<template>
  <div class="w-full h-full min-h-screen whitespace-pre-line">
    <div class="flex items-start w-full h-full">
      <menuNav
        v-if="
          $route.name === 'UserManage' ||
          $route.name === 'FundReq' ||
          $route.name === 'FundExp' ||
          $route.name === 'MidCanc' ||
          $route.name === 'EventManage' ||
          $route.name === 'VisitManage' ||
          $route.name === 'NoMbrManage' ||
          $route.name === 'NewMbrReg'
        "
      />
      <router-view class="flex-1 w-full h-full" />
    </div>
    <SplashView class="hidden" />
    <BaseAlert
      v-if="CommonStore.errState"
      :title="CommonStore.errMsg.error"
      :body="CommonStore.errMsg.message"
      :btnText="message.button.confirm[CommonStore.language]"
      @onClose="handleAlertClose"
    />
  </div>
</template>

<script setup>
import { onMounted, reactive, computed, watchEffect, watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import message from '@/lib/message.json';
import Request from '@/utils/api/Request';
import getLog from './utils/getLog';

import SplashView from '@/feature/common/components/SplashView.vue';
import BaseAlert from './feature/common/components/BaseAlert.vue';
import MenuNav from '@/feature/common/components/MenuNav.vue';

import { rootStore } from '@/store/rootStore';
const CommonStore = reactive(rootStore?.commonStore());

const handleAlertClose = () => {
  CommonStore.errState = false;
};

const router = useRouter();
const route = useRoute();

const chkRefresh = async () => {
  const local = localStorage.getItem('owt');

  if (!local) {
    router.push('/login');
  } else {
    let result = await Request('/owtab/api/main/v1/request_main_state', 'post');
    if (!result) return;
    getLog('app_refresh api', result);
    if (result.API_CODE.status === '0000') {
      CommonStore.isLogin = true;
    }
  }
};

watchEffect(() => {
  if (CommonStore.goLoginPage) {
    router.push('/login');
    CommonStore.goLoginPage = false;
  }

  if (CommonStore.isLogin && route.path === '/login') {
    router.push('/');
  }
});

onMounted(() => {
  window.scrollTo(0, 0);
  chkRefresh();
});
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ecf2e1;
  color: #222;
  font-family: 'Pretendard-Regular';
}

button {
  touch-action: manipulation;
}

input[type='date'] {
  position: relative;
  appearance: none;
}
input[type='date']::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /*화살표 배경 넣기*/
  background: #fff url('@/assets/drop-down.png') no-repeat 98% center;
  background-size: 2rem;
}

@media (orientation: portrait) {
  /* Portrait 모드일 때 적용할 CSS */
  html {
    font-size: 1.302vw;
  }
}

@media (orientation: landscape) {
  /* Landscape 모드일 때 적용할 CSS */
  html {
    font-size: 1.3vw;
  }
}
</style>
