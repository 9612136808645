import CommonStore from './commonStore';
import LoginStore from '@/feature/login/store/LoginStore';
import UserStore from '@/feature/user/store/UserStore';
// import AllotStore from '@/feature/allot/store/AllotStore';
import VisitStore from '@/feature/visit/store/VisitStore';
import EventStore from '@/feature/event/store/EventStore';
import NoMbrStore from '@/feature/noUser/store/NoMbrStore';
import NewMbrStore from '@/feature/newUser/store/NewMbrStore';
import FundReqStore from '@/feature/fundReq/store/FundReqStore';
import FundExpStore from '@/feature/fundExp/store/FundExpStore';
import MidCancStore from '@/feature/midCanc/store/MidCancStore';

export const rootStore = {
  commonStore: CommonStore,
  loginStore: LoginStore,
  userStore: UserStore,
  // allotStore: AllotStore,
  visitStore: VisitStore,
  eventStore: EventStore,
  noMbrStore: NoMbrStore,
  fundReqStore: FundReqStore,
  fundExpStore: FundExpStore,
  newMbrStore: NewMbrStore,
  midCancStore: MidCancStore
};
