import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultInputData = {
  reChkPaper: false,
  quantity: '',
  fundDueDate: '',
  fundAmt: '',
  cashBack: '',
  cashBackAmt: '',
  returnType: '',
  returnBank: ''
};

const defaultBankInput = {
  bkName: '',
  bkArea: '',
  bkCode: '',
  bkType: '',
  bkNum: '',
  bkOwner: '',
  sendReason: ''
};

const state = {
  isResetStart: false,
  userUuid: '',
  agreeSeq: '',
  fundSeq: '',
  fundUserSeq: '',
  chkInfoSeqs: '',
  selectResetType: -1,
  fundData: {},
  resetInputData: defaultInputData,
  bankInputData: defaultBankInput
};

const mainState = { ...state };

const FundExpState = (set, get) => ({
  ...mainState,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setSelectedUserData(userData) {
    set({ selectedUserData: userData });
  },
  setBankReset() {
    set((state) => {
      return {
        ...state,
        bankInputData: { ...defaultBankInput }
      };
    });
  },
  resetInput() {
    set((state) => {
      return {
        ...mainState,
        resetInputData: {
          reChkPaper: false,
          quantity: '',
          fundAmt: '',
          fundDueDate: '',
          cashBack: '',
          cashBackAmt: '',
          returnType: '',
          returnBank: ''
        },
        bankInputData: {
          bkName: '',
          bkArea: '',
          bkCode: '',
          bkType: '',
          bkNum: '',
          bkOwner: '',
          sendReason: ''
        }
      };
    });
  }
});

const FundExpStore = create(createStore(FundExpState));

export default FundExpStore;
