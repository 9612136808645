import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultInputData = {
  cashBack: '',
  cashBackAmt: '',
  returnType: '',
  returnBank: '',
  receiptYn: ''
};

const defaultInputBank = {
  bkName: '',
  bkArea: '',
  bkCode: '',
  bkType: '',
  bkNum: '',
  bkOwner: '',
  sendReason: ''
};

const state = {
  refresh: false,
  isStopStart: false,
  userUuid: '',
  agreeSeq: '',
  fundSeq: '',
  fundUserSeq: '',
  userData: {
    fundData: {},
    bankData: {}
  },
  inputData: defaultInputData,
  inputBank: defaultInputBank
};

const MidCancState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setSelectedUserData(userData) {
    set({ selectedUserData: userData });
  },
  setBankReset() {
    set((state) => {
      return {
        ...state,
        bankInputData: {
          bkName: '',
          bkArea: '',
          bkCode: '',
          bkType: '',
          bkNum: '',
          bkOwner: '',
          sendReason: ''
        }
      };
    });
  },
  resetInput() {
    set((state) => {
      return {
        ...state,
        inputData: {
          cashBack: '',
          cashBackAmt: '',
          returnType: '',
          returnBank: '',
          receiptYn: ''
        },
        inputBank: {
          bkName: '',
          bkArea: '',
          bkCode: '',
          bkType: '',
          bkNum: '',
          bkOwner: '',
          sendReason: ''
        }
      };
    });
  }
});

const MidCancStore = create(createStore(MidCancState));

export default MidCancStore;
