import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // 로그인
  {
    path: '/login',
    name: 'UserLogin',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/feature/login/views/UserLogin.vue'
      )
  },
  // 회원관리
  {
    path: '/',
    name: 'UserManage',
    component: () =>
      import(/* webpackChunkName: "main" */ '@/feature/user/views/UserView.vue')
  },
  // 회원관리 상세보기페이지
  {
    path: '/user/:id/:type',
    name: 'UserDetail',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/user/views/UserDetail.vue'
      ),
    props: true
  },
  // 상세보기페이지
  // {
  //   path: '/detail',
  //   name: 'DetailPage',
  //   component: () => import(/* webpackChunkName: "detail" */ '@/components/detail/DetailPage.vue')
  // },
  // 기금신청
  {
    path: '/fundReq',
    name: 'FundReq',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/fundReq/views/FundReqView.vue'
      )
  },
  // 기금만기
  {
    path: '/fundExp',
    name: 'FundExp',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/fundExp/views/FundExpView.vue'
      )
  },
  // 중도해약
  {
    path: '/midCanc',
    name: 'MidCanc',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/midCanc/views/MidCancView.vue'
      )
  },
  // 분배금관리
  {
    path: '/distribution',
    name: 'DistributionManage',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/components/distribution/DistributionManage.vue'
      )
  },
  // 방문/응대관리
  {
    path: '/visit/:type',
    name: 'VisitManage',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/visit/views/VisitView.vue'
      ),
    props: true
  },
  // 응대이력 상세보기
  {
    path: '/visit/:id/detail',
    name: 'ResHx',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/visit/views/ResHx.vue'
      ),
    props: true
  },
  // 신규응대등록
  {
    path: '/visit/newRes',
    name: 'NewRes',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/visit/views/NewRes.vue'
      )
  },
  // 방문예약상세보기
  {
    path: '/visitDet/:id/detail',
    name: 'VisitDet',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/visit/views/VisitDet.vue'
      )
  },
  // 방문예약신규등록
  {
    path: '/visit/newVisit',
    name: 'NewVisit',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/visit/views/NewVisit.vue'
      )
  },
  // 이벤트관리
  {
    path: '/event',
    name: 'EventManage',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/event/views/EventView.vue'
      )
  },
  // 이벤트상세
  {
    path: '/eventDetail/:seq',
    name: 'EventDetail',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/event/components/EventDetail.vue'
      )
  },
  // 비회원관리
  // {
  //   path: '/noMbr',
  //   name: 'NoMbrManage',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "detail" */ '@/feature/noUser/views/NoMbrView.vue'
  //     )
  // },
  // 비회원신규등록
  // {
  //   path: '/regNoMbr',
  //   name: 'NoMbrReg',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "detail" */ '@/feature/noUser/views/NoMbrReg.vue'
  //     )
  // },
  // 비회원상세보기
  // {
  //   path: '/noMbrDet/:seq',
  //   name: 'NoMbrDet',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "detail" */ '@/feature/noUser/views/NoMbrDet.vue'
  //     )
  // },
  // 신규회원등록
  {
    path: '/new',
    name: 'NewMbrReg',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '@/feature/newUser/views/NewMbrReg.vue'
      )
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
