import { createStore } from 'zustand/vanilla';
import create from 'vue-zustand';

const defaultInputData = {
  fundDueDate: '',
  fundAmt: '',
  fundPayType: '',
  selectFundSeq: '',
  selectFund: {}
};

const state = {
  // 기금 신청 모달
  isFundStart: false,
  replyInfoList: {
    user: {
      nonUserSeq: '',
      userUuid: '',
      nonUserCate: '',
      userCode: '',
      lastName: '',
      firstName: '',
      furiLname: '',
      furiFname: '',
      enLname: '',
      enFname: '',
      userType: '',
      userCate: '',
      gender: '',
      birth: '',
      eraCode: '',
      mobile: '',
      phone: '',
      email: '',
      lineId: ''
    },
    detail: {
      replySeq: '',
      impoLvl: '',
      title: '',
      mngName: '',
      replyWay: '',
      workCase: '',
      closeDate: '',
      roomDesc: '',
      replyBody: '',
      filePath: '',
      filePname: '',
      fileLname: '',
      state: '',
      mdfyDate: '',
      mdfyDp: '',
      mdfyNamePos: ''
    },
    searchList: [
      {
        furigana: '',
        gender: '',
        name: '',
        mobile: '',
        birth: '',
        userType: '',
        userCode: '',
        userSeq: ''
      }
    ]
  },
  selectedUserData: {
    nonUserSeq: '',
    userUuid: '',
    userCode: '',
    lastName: '',
    firstName: '',
    furiLname: '',
    furiFname: '',
    enLname: '',
    enFname: '',
    userType: '',
    userCate: '',
    gender: '',
    birth: '',
    eraCode: '',
    mobile: '',
    phone: '',
    email: '',
    lineId: ''
  },
  // 기금신청서 input data
  fundInputData: defaultInputData,
  selectedButtonIndex: null,
  modalType: {
    list: ['doc', 'req', 'confirm', 'complete'],
    select: 'doc'
  },
  newSearchModal: {
    show: false
  },
  userUuid: '',
  agreeSeq: '',
  fundUserSeq: '',
  chkInfoSeqs: ''
};

const FundReqState = (set, get) => ({
  ...state,
  setSelectedButtonIndex: (index) => set({ selectedButtonIndex: index }),
  setPage(value) {
    set((state) => {
      return {
        pageType: {
          ...state.pageType,
          select: value
        }
      };
    });
  },
  setModalControl(key, value, modalType, addrType) {
    set((state) => {
      return {
        [modalType]: {
          ...state[modalType],
          [key]: value,
          type: addrType
        }
      };
    });
  },
  setCommonData(key, value) {
    set((state) => {
      return {
        ...state,
        [key]: value
      };
    });
  },
  setSelectedUserData(userData) {
    set({ selectedUserData: userData });
  },
  reset() {
    set((state) => {
      return {
        ...state,
        fundInputData: { ...defaultInputData }
      };
    });
  }
});

const FundReqStore = create(createStore(FundReqState));

export default FundReqStore;
