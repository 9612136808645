<template>
  <div :class="tStyle.menuBarWrap">
    <img
      src="@/assets/logo.png"
      alt=""
      :class="tStyle.logo"
      @click="goMainPage"
    />
    <div :class="tStyle.menuBarInner">
      <div :class="tStyle.userInfo">
        <p>[{{ CommonStore.adminInfo?.adminCode }}]</p>
        <p>
          {{
            CommonStore.adminInfo?.adminLname +
            CommonStore.adminInfo?.adminFname
          }}
        </p>
        <button @click="logout()">
          <img src="@/assets/mypage.png" alt="" :class="tStyle.img" />
        </button>
      </div>
      <div :class="tStyle.menuWrap">
        <div
          :class="[
            tStyle.menu,
            {
              'font-bold text-key bg-gradient-to-r to-conTo from-conFrom shadow-btn':
                ($route.name === 'UserManage' && i === 0) ||
                ($route.name === 'FundReq' && i === 1) ||
                ($route.name === 'FundExp' && i === 2) ||
                ($route.name === 'MidCanc' && i === 3) ||
                ($route.name === 'VisitManage' && i === 4) ||
                ($route.name === 'EventManage' && i === 5) ||
                ($route.name === 'NewMbrReg' && i === 6)
            }
          ]"
          v-for="(a, i) in message.menu.nav"
          :key="i"
          @click="navPage(i)"
        >
          {{ a[language] }}
        </div>
      </div>
      <p :class="tStyle.time" @click="hiddenBtn">
        {{ message.menu.time[language] }}<br />{{ getTime }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

import message from '@/lib/message.json';
import Request from '@/utils/api/Request';

import { rootStore } from '@/store/rootStore';

const messages = ref(message);
const CommonStore = reactive(rootStore?.commonStore());

const language = ref(rootStore?.commonStore().language);

const data = reactive({
  time: '',
  changeCount: 0
});

const hiddenBtn = () => {
  if (data.changeCount < 6) {
    data.changeCount++;
  } else {
    CommonStore.language = CommonStore.language == 'jp' ? 'kr' : 'jp';
    data.changeCount = 0;
  }
};

const router = useRouter();
const goMainPage = () => {
  router.push({ name: 'UserManage' });
};

const logout = () => {
  CommonStore.setLogOut();
  router.push('/login');
};

const navPage = (index) => {
  if (index === 0) {
    router.push({ name: 'UserManage' });
  } else if (index === 1) {
    router.push({ name: 'FundReq' });
  } else if (index === 2) {
    router.push({ name: 'FundExp' });
  } else if (index === 3) {
    router.push({ name: 'MidCanc' });
  } else if (index === 4) {
    router.push(`/visit/manage`);
  } else if (index === 5) {
    router.push({ name: 'EventManage' });
  } else if (index === 6) {
    router.push('/new');
  }
};

const getTime = computed(() => {
  setInterval(() => {
    const date = new Date();
    const day = date.toLocaleDateString();
    const time = date.toLocaleTimeString();
    data.time = day + ' \n' + time;
  }, 1000);
  return data.time;
});

onMounted(() => {});

const tStyle = ref({
  menuBarWrap:
    'flex flex-col gap-y-[1rem] items-start sticky top-0 ml-[4rem] w-[9.8rem] pt-[2.4rem] h-screen',
  logo: 'w-[8.5rem]',
  menuBarInner: 'flex flex-col gap-y-[0.6rem] w-full items-center',
  userInfo:
    'w-full flex flex-col items-center justify-center text-[1rem] text-[#292929] font-medium tracking-[-0.2px]',
  menuWrap:
    'shadow-input bg-[#EBF0DB] rounded-[0.8rem] text-center text-[#999] font-semibold text-[1.1rem] pt-[14px] leading-[1.3rem] pb-[10px] px-[8px] w-full flex flex-col gap-y-[12px]',
  menu: 'rounded-[0.6rem] py-[0.75rem]',
  time: 'text-[#7e756b] font-semibold text-[1rem] text-center',
  img: 'w-[2rem] shadow-img rounded-full'
});
</script>
<style scoped></style>
